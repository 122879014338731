import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import StyledWrapper from './styles';


const TicketButton: React.StatelessComponent<Props> = () => (
  <StaticQuery
    query={graphql`
      query ticketLink {
        wp {
          acfOptionsThemeSettings {
            themeOptions {
              ticketLink {
                target
                title
                url
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      return (
        <>
        {data?.acfOptionsThemeSettings?.themeOptions?.ticketLink &&
          <StyledWrapper>
            <a href={data.acfOptionsThemeSettings.themeOptions.ticketLink.url} target={data.acfOptionsThemeSettings.themeOptions.ticketLink.target}>{data.acfOptionsThemeSettings.themeOptions.ticketLink.title}</a>
          </StyledWrapper>
        }
        </>
      )
    }}
  />
);

export default TicketButton;
