
import React from 'react';
import SOCIALS from 'constants/socials';
import WEBSITES from 'constants/websites';
import { PageLinkType } from 'types';
import IconButton from 'components/IconButton';
import SocialLinks from 'components/SocialLinks';
import LogoImage from 'assets/images/SITClogo.png';
import MountainLeft from 'assets/images/cityscape-left-cropped.png';
import MountainRight from 'assets/images/cityscape-right-cropped.png';
import Edge from 'assets/images/flowers-top.png';

import Image from 'components/Image';
import Partners from 'components/Partners';
import { ArrowUp } from 'icons';
import FooterWrapper, {
  Acknowledgement,
  BackToTop,
  Logo,
  ContentWrapper,
  Credits,
  FooterInner,
  FooterLandscape,
  SubscribeWrapper,
  SiteLinks,
  SocialLinksWrapper,
  ArrowButton,
} from './styles';

const handleScroll = (pageWrapId) => {
  const page = pageWrapId ? document.getElementById(pageWrapId) : window;

  if (page) {
    page.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
};

interface Props {
  mainMenuItems: PageLinkType[];
  secondaryFooterItems?: PageLinkType[];
  partnerItems?: any;
  partnerTopItems?: any;
  pageWrapId?: string;
  openSubscribeMenu: () => void;
}

const Footer: React.FunctionComponent<Props> = ({
  secondaryFooterItems,
  pageWrapId,
  openSubscribeMenu,
}) => (
  <FooterWrapper>
    <FooterLandscape>
      <Image className="mountain-left" sourceUrl={MountainLeft} />
      <Image className="edge" sourceUrl={Edge} />
      <Image className="mountain-right" sourceUrl={MountainRight} />
    </FooterLandscape>
    <FooterInner>
      <ContentWrapper>
        <Acknowledgement className="p4">
          <p>We respectfully acknowledge the original and traditional custodians of this land the Gadigal People of the Eora Nation. We also respectfully acknowledge, thank and pay our respects to the elders both past, present and emerging.</p>
        </Acknowledgement>
        <Partners />
        <SocialLinksWrapper>
          <SocialLinks links={SOCIALS} />
        </SocialLinksWrapper>
        <Logo>
          <Image sourceUrl={LogoImage} />
        </Logo>
        <SubscribeWrapper>
          <BackToTop>
            <ArrowButton onClick={() => handleScroll(pageWrapId)} size="medium" color="whitePink" inverted={true}>
              <span>
                <span><ArrowUp /></span>
              </span>
            </ArrowButton>
          </BackToTop>
          <IconButton type="paperPlane" size="small" text="Subscribe" inverted={true} color="black" onClick={openSubscribeMenu} />
        </SubscribeWrapper>
        {secondaryFooterItems && (
          <SiteLinks>
            {secondaryFooterItems.map(({ label: title, url }: PageLinkType, index) => (
              <a key={index} href={url}>
                <span className="h9 subcontent">{title}</span>
              </a>
            ))}
          </SiteLinks>
        )}
        <Credits className="h9 subcontent">
          <span>© Copyright Splendour In The City {new Date().getFullYear()}</span>
          <div className="separator">{'•'}</div>
          <span>Original Art & Creative by Lucy Dyson</span>
          <div className="separator">{'•'}</div>
          <span>
            Website by{' '}
            <a href={WEBSITES.BOLSTER} target="_blank">
              Bolster
            </a>
          </span>
        </Credits>
      </ContentWrapper>
    </FooterInner>
  </FooterWrapper>
);

export default Footer;
