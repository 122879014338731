import IconButton from 'components/IconButton';
import styled from 'styled-components';
import headerImage from 'assets/images/hero-tear-blue.png';
import headerShadow from 'assets/images/header-background-shadow.png';
import Button from 'components/Button/styles';
import { ThemeType } from 'types';

export default styled.footer(
  ({ theme: { colors } }) => `
    width: 100%;
    color: ${colors.black};
    letter-spacing: 0.5px;
`,
);

export const Acknowledgement = styled.div(
  ({ theme: { spacing } }) => `
  grid-area: acknowledge;
  text-align: center;
  margin: 0 auto ${spacing.x2};
  max-width: 640px;
`);
export const ArrowButton = styled(Button)(
  ({
    theme: {
      colors,
      utils: { rgba },
    },
  }: ThemeType) => `
    height: 48px;
    width: 42px;
    z-index: 100;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-align: right;
    transform: translateY(-20px);

    > span {
      height: 48px;
      width: 42px;
      box-shadow: 3px 3px 5px ${rgba(colors.eggplant, 0.1)};
      border-top-left-radius: 42px;
      padding: 0;
      padding-top: 13px;

      span {
        font-family: sans-serif;
        font-size: 15px;
        svg {
          width: 10px;
        }
      }
    }

    &:after {
      border-top-left-radius: 42px;
    }
`,
);

export const FooterInner = styled.div(
  ({ theme: { colors } }) => `
    background:${colors.blue};
`,
);

export const FooterLandscape = styled.div(
  ({ theme: { colors, mobileView } }) => `
    position: relative;
    height: 30vh;
    overflow: hidden;
    .sun {
      max-width: 242px;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%,50%);
      z-index: 1;
      width: auto;
      max-height: 40%;
    }
    .mountain-left, .mountain-right {
      position: absolute;
      bottom: 5vw;
      width: auto;
      max-width: 45vw;
      z-index: 2;
      height: 50%;
      max-height: 150px;
      ${mobileView} {
        max-width: 100%;
      }
    }
    .mountain-left {
      left: 40%;
      transform: translate(-100%, -1vw);
      ${mobileView} {
      }
    }
    .mountain-right {
      right: 40%;
      transform: translate(100%, -1vw);
      ${mobileView} {
      }
    }
    .edge {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
      z-index: 4;
      min-width: 100%;
    }
    &:after {
      pointer-events: none;
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 32px;
      background-image: url(${headerImage});
      background-size: 812px 63px;
      background-position: bottom center;
      z-index: 10;
      transform: rotate(180deg);
    }
    &:before {
      content: "";
      display: block;
      pointer-events: none;
      width: calc(100% + 16px);
      height: 48px;
      background-position: bottom center;
      background-image: url(${headerShadow});
      background-size: 812px 63px;
      position: absolute;
      bottom: 8px;
      left: -8px;
      z-index: 9;
      opacity: 0.08;
      transform: translateX(8px) rotate(180deg);
    }
`,
);

export const ContentWrapper = styled.div(
  ({ theme: { mobileView, spacing, width } }) => `
    max-width: ${width.tablet};
    margin: 0 auto;
    padding: ${spacing.x4} 0 ${spacing.x1} ${spacing.x6};

    ${mobileView} {
      padding: ${spacing.x6} ${spacing.x3} ${spacing.x2};
    }

    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    grid-template-areas: 
    "acknowledge acknowledge acknowledge"
    "social-links logo subscribe"
    "site-map site-map site-map"
    "partners partners partners"
    "site-links site-links site-links"
    "credits credits credits";

    ${mobileView} {
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
      "acknowledge acknowledge"
      "social-links social-links"
      "logo logo"
      "subscribe subscribe"
      "partners partners"
      "site-links site-links"
      "credits credits";
    }

`,
);
export const SubscribeWrapper = styled.div(({ theme: { mobileView } }) => {
  return `
    grid-area: subscribe;
    text-align: right;
    ${IconButton} {
      margin-left: auto;
    }
    ${mobileView} {
      text-align: center;
      ${IconButton} {
        margin: auto;
      }
    }
`;
});
export const Logo = styled.div(
  ({ theme: { mobileView, spacing } }) => `
  grid-area: logo;
  max-width: 237px !important;
  margin: auto;
  ${mobileView} {
    margin: ${spacing.x2} auto;
  }
`,
);

export const BackToTop = styled.div(
  ({ theme: { mobileView, spacing } }) => `
    grid-area: back-to-top;
    padding-bottom: ${spacing.x2};
    
    ${mobileView} {
      padding-bottom: 0;
      display: none;
    }

    & > * {
      float: right;
    }
`,
);

export const SiteMapWrapper = styled.div(
  ({ theme: { spacing, colors, mobileView } }) => `
  grid-area: site-map;
  padding-top: ${spacing.x4};
  padding-bottom: ${spacing.x4};
  border-bottom: 1px solid ${colors.white40};
  display: flex;
  width: 100%;
  justify-content: space-between;

  ${mobileView} {
    display: none;
  }
`,
);

const ContentBlock = styled.div(
  ({ theme: { mobileView, spacing } }) => `
    padding-bottom: ${spacing.x2};
`,
);

export const SiteLinks = styled(ContentBlock)(
  ({ theme: { fontSizes, mobileView, spacing } }) => `
  grid-area: site-links;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-content: start;
  font-size: ${fontSizes.micro};
  line-height: 19px;

  & > *:not(:first-child) {
    margin-left: ${spacing.x1};
  }

  & > * {
    margin: 0 ${spacing.x1};
  }

  ${mobileView} {
    justify-content: center;
    align-content: center;
    align-items: center;
    margin: ${spacing.x2} auto;
  }
`,
);

export const SocialLinksWrapper = styled(ContentBlock)(
  ({ theme: { mobileView } }) => `
  grid-area: social-links;
  padding-bottom: 0;

  ${mobileView} {
    text-align: center;
  }
`,
);

export const Credits = styled.div(
  ({ theme: { fontSizes, mobileView, spacing, colors } }) => `
    grid-area: credits;
    margin: 0 auto;
    display: flex;
    text-transform: uppercase;
    color: ${colors.white};
    font-size: ${fontSizes.micro};
    line-height: 19px;
    margin-bottom: 4px;

    .separator {
      margin: 0 ${spacing.x1};
    }

  ${mobileView} {
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;

    .separator {
      display: none;
    }
  }
`,
);
