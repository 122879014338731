import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { SocialsType, IconSizeType } from 'types';
import IconLink from '../IconLink';
import StyledWrapper from './styles';

interface Props {
  size?: IconSizeType;
}

const SocialLinks: React.StatelessComponent<Props> = ({ size }) => (
  <StaticQuery
    query={graphql`
      query socials {
        wp {
          acfOptionsThemeSettings {
              themeOptions {
                moodagent
                facebook
                email
                instagram
                spotify
                twitter
                youtube
                tiktok
              }
            }
          }
      }
    `}
    render={(data) => (
      <StyledWrapper>
        {Object.entries(data.wp.acfOptionsThemeSettings.themeOptions).map((key, index) => (
          <>{key && key.length && key[1] && <IconLink key={index} type={key[0]} size={size} href={key[1]} />}</>
        ))}
      </StyledWrapper>
    )}
  />
);

export default SocialLinks;
