import React from 'react';
import { MenuType } from 'types';
import IconButton from 'components/IconButton';
import { MenuBody, MenuContent, SlideMenuBg, SlideMenuWrapper } from './styles';
import { useKeyPress } from 'hooks';
import theme from 'styles/theme';

interface Props extends MenuType {
  children: React.ReactNode;
}

const SlideMenu: React.FunctionComponent<Props> = ({
  isOpen,
  right,
  children,
  close,
  openSubscribeMenu,
  showSubscribe = false,
  scroll = false,
  width,
  mobileWidth,
  maxWidth,
}) => {
  useKeyPress('Escape', close);

  return (
    <SlideMenuWrapper isOpen={isOpen} right={!!right}>
      <SlideMenuBg width={width} maxWidth={maxWidth} mobileWidth={mobileWidth} isOpen={isOpen} right={!!right} />
      <MenuBody
        color={theme.colors.black}
        width={width || '100%'}
        maxWidth={maxWidth}
        mobileWidth={mobileWidth}
        isOpen={isOpen}
        right={!!right}
      >
        <MenuContent right={!!right} scroll={scroll}>
          <div className="menu-header">
            {showSubscribe && <IconButton type="paperPlane" text="Subscribe" onClick={openSubscribeMenu || (() => null)} />}
            <IconButton type="close" onClick={close} inverted={true} color='blue' />
          </div>
          {children}
        </MenuContent>
      </MenuBody>
    </SlideMenuWrapper>
  );
};

export default SlideMenu;
