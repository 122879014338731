import IconButton from 'components/IconButton/styles';
import Cta from 'components/Cta/styles';
import styled, { keyframes, css } from 'styled-components';

export default styled.div(
  ({ theme: { colors, linearGradient, mobileView, spacing } }) => `
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${spacing.x2} ${spacing.x6};
  color: ${colors.black};
  background-color: ${colors.lightGold};
  position: relative;
  z-index: 1001;

  & > *:not(:last-child) {
    padding-right: ${spacing.x4};
  }
  
  & > .h7 {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    ${mobileView} {
      flex-direction: column;
      ${Cta}{
        margin-top: ${spacing.x2};
      }
    }
  }
  
  ${mobileView} {
    padding: ${spacing.x1} ${spacing.x3};
    & > *:not(:last-child) {
      padding-right: 0;
    }
  }
`,
);

export const ModalAnnouncement = styled.div(
  ({ theme: { colors, mobileView, spacing, utils: { rgba }, }, showForm }: Props) => `
    display: flex;
    z-index: 1001;
    position: fixed;
    display: block;
    background-color: ${rgba(colors.black, 0.5)};
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    padding: ${spacing.x2};
    > div {
      height: auto;
      width: 100%;
      max-width: 650px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: ${spacing.x4};
      background-color: ${colors.pink};
      text-align: center;
      .h3{
        margin-bottom: 0.5em;
      }
      ${mobileView} {
        padding: ${spacing.x2};
        max-width: 90%;
      }
      ${Content} {
        display: block;
        margin-bottom: ${spacing.x2};
      }
      ${IconButton} {
        position: absolute;
        top: ${spacing.x2};
        right: ${spacing.x2};
        ${mobileView} {
          top: ${spacing.x1};
          right: ${spacing.x1};
        }
      }
    }
  `);

export const FooterBanner = styled.div(
  ({ theme: { colors, mobileView, spacing }, showForm }: Props) => css`
    width: 100%;
    display: flex;
    align-items: center;
    background-color: ${colors.pink};
    padding: ${spacing.x4} ${spacing.x6};
    color: ${colors.black};
    position: fixed;
    max-height: 100%;
    left: 0;
    bottom: 0;
    z-index: 1001;
    animation: 5s ease-out 0s 1 ${slideUp};
    &::before {
      content: ' ';
      position: absolute;
      height: 2px;
      width: 100%;
      top: 0;
      left: 0;
    }
    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      width: 100%;
      > input[type='checkbox'] {
        display: none;
      }
    }
    ${IconButton} {
      position: absolute;
      top: ${spacing.x2};
      right: ${spacing.x2};
      ${mobileView} {
        top: ${spacing.x1};
        right: ${spacing.x1};
      }
    }
    ${mobileView} {
      padding: ${spacing.x4} ${spacing.x3} ${spacing.x4};
      & > *:not(:last-child) {
        padding-right: ${spacing.x2};
      }
    }
  `,
);
const slideUp = keyframes `
0% {
  transform: translateY(100%);
}
90% {
  transform: translateY(100%);
}
100% {
  transform: translateY(0);
}
`
export const Title = styled.h4(
  ({ theme: { spacing } }) => css`
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    display: block;
    margin-bottom: 0.5em;
  `,
);

export const Content = styled.div(
  ({ theme: { spacing } }) => `
  display: inline-block;
  margin-right: ${spacing.x1};
  p {
    margin: 0;
  }
`,
);

export const SubscribeWrapper = styled.div(
  ({ theme: { spacing } }) => `
    width: 600px;
    max-width: 100%;
  `,
);
