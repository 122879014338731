import React from 'react';
import isBrowser from 'utils/isBrowser';
import { AdSlot } from 'react-dfp';
const Ad: React.FunctionComponent<any> = ({ width = 728, height = 90 }) => {
  if (!isBrowser()) {
    return null;
  }

  return (
    <>
      <div className="desktop-ads">
        <AdSlot sizes={[width, height]} />
      </div>
      <div className="mobile-ads">
        <AdSlot sizes={[300, 250]} />
      </div>
    </>
  );
};

export default Ad;
