import React from 'react';
import Button from 'components/Button';
import SubscribeConsumer from 'context/SubscribeContext';

const Form: React.FunctionComponent = ({ onSubmit, loading, children }) => (
  <SubscribeConsumer>
    {({ openedMenu, openSubscribeMenu }) => (
      <Button inverted={true} onClick={openSubscribeMenu || (() => null)}>
        {children ? children : 'Subscribe'}
      </Button>
    )}
  </SubscribeConsumer>
);

export default Form;
