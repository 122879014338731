import React from 'react';
import { LineupType } from 'types';
import Block from 'components/Block';
import Title from 'components/Title';
import TitleWrapper from 'components/TitleWrapper';
import Cta from 'components/Cta';
import LineupRow from 'components/LineupRow';
import ArtistConsumer from 'context/ArtistContext';
import StyledLineupListBlock from './styles';
import isMobile from 'utils/isMobile';

interface Props extends LineupType {
  title: string;
}

const LineupList: React.FunctionComponent<Props> = ({ title, artists, ctalink }) => (
  <StyledLineupListBlock>
    <ArtistConsumer>
      {({ artist: selectedArtist, updateArtist }) => (
        <Block>
            <TitleWrapper>
              <Title>{title}</Title>
              {!isMobile() && <Cta {...ctalink} color="blue" />}
            </TitleWrapper>
            {artists.map((artist) => (
              <LineupRow
                {...artist}
                fade={!!selectedArtist && selectedArtist.id !== artist.id}
                selectArtist={(id) => updateArtist(artists.find(({ id: artistId }) => artistId === id))}
              />
            ))}
          </Block>
      )}
    </ArtistConsumer>
  </StyledLineupListBlock>
);

export default LineupList;
