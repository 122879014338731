import styled from 'styled-components';
import { ThemeType } from 'types';
import Button from 'components/Button/styles';

const Arrow = styled(Button)(
  ({
    theme: {
      mobileView,
      colors,
      utils: { rgba },
    },
  }: ThemeType) => `
    width: 48px;
    cursor: pointer;
    top: 0;
    display: flex;
    align-items: center;

    > span {
      width: 48px;
      box-shadow: 3px 3px 5px ${rgba(colors.eggplant, 0.1)};

      span {
        font-family: sans-serif;
        font-size: 15px;
      }
    }
`,
);
export const NextArrow = styled(Arrow)(
  ({ theme: { mobileView } }: ThemeType) => `
    text-align: left;
    align-self: flex-end;
    left: auto;
    transform: translateY(-0.2em);
    > span {
      border-bottom-right-radius: 42px;
      padding: 0;
      padding-right: 13px;

      span {
        transform: translateY(-1px);
      }
      svg{
        height: 10px;
      }
    }

    &:after {
      border-bottom-right-radius: 42px;
    }
`,
);
export default styled.div(
  ({ theme: { colors, mobileView, spacing } }) => `
    padding: ${spacing.x1} ${spacing.x3};
    border-top: 1px solid ${colors.pink};
    line-height: 2;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    &:last-of-type {
      border-bottom: 1px solid ${colors.pink};
    }
    ${mobileView} {
      min-height: ${spacing.x8};
      padding: ${spacing.x3} 0 ${spacing.x3} ${spacing.x2};
    }
`,
);

export const Session = styled.span(
  ({ theme: { spacing, lineupSeparator, colors } }) => `
    display: inline-block;
    color: ${colors.black};
    font-weight: 500;
    &:not(:last-child) {
      padding-right: ${spacing.x2};
      position: relative;
      &:after {
        content: ' ';
        position: absolute;
        right: 6px;
        top: calc(50% - 2px);
        height: 4px;
        width: 4px;
        background-image: ${lineupSeparator};
        background-size: contain;
        background-position: 50% 25%;
        background-repeat: no-repeat;
      }
    }
`,
);

export const Title = styled.h4(
  ({ theme: { spacing }, fade }: ThemeType & { fade: boolean }) => `
    margin-right: ${spacing.x1};
    cursor: pointer;
    ${fade ? 'opacity: 0.6' : ''};

    &:hover {
      text-decoration: underline;
    }
`,
);

export const Sessions = styled.div(
  ({ theme: { mobileView } }) => `
    flex: 1;
    
    ${mobileView} {
      display: none;
    }
`,
);
