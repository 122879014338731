import styled from 'styled-components';
import theme from 'styles/theme';
import logo from 'assets/svg/hashtag-logo.svg';

const StyledLogo = styled.a(
  ({ theme: { mobileView } }) => `
  display: block;
  font-size: 0;
  background: url(${logo});
  background-position: 50% 50%;
  background-repeat: no-repeat;
  width: 200px;
  height: 17px;
`,
);

export default StyledLogo;
