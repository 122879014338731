import styled from 'styled-components';
import Layout from 'components/Layout';
import theme from 'styles/theme';
import heroImageLime from 'assets/images/hero-tear-blue.png';
import headerShadow from 'assets/images/header-background-shadow.png';
import { ThemeType } from 'types';


const bgColours = {
  lime: {
      background: theme.colors.blue,
      tear: heroImageLime,
      color: theme.colors.black,
  },
  default: {
    background: 'transparent',
    tear: null,
    color: theme.colors.white,
  }
}

export const StyledSection = styled(Layout.Section)(
  ({ theme: { mobileView, colors, spacing }, color, isFirstSection }: ThemeType) => {
    const theme = color ? bgColours[color] : bgColours['default'];
    
    return `
    background-color: ${theme.background};
    color: ${theme.color};
    position: relative;
    z-index: ${isFirstSection ? 5: 3};
    padding: ${spacing.x4} ${spacing.x4};
    
    ${mobileView} {
      padding: ${spacing.x2} 0;
    }
    
    ${theme.tear &&
      `
      margin-bottom: 32px;
      &:after {
        content: "";
        width: 100%;
        height: 32px;
        background-position: bottom center;
        background-image: url(${theme.tear});
        background-size: 812px 63px;
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 5;
      }
      
      &:before {
        content: "";
        width: 100%;
        height: 48px;
        background-position: bottom center;
        background-image: url(${headerShadow});
        background-size: 812px 63px;
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 0;
        opacity: 0.08;
        transform: translateY(8px);
      }`
    }
`},
);
