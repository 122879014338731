import styled from 'styled-components';
import theme from 'styles/theme';
import { ThemeType } from 'types';

export const colors = {
  default: {
    front: theme.colors.pink,
    back: theme.colors.white,
    text: theme.colors.black,
  },
  pink: {
    front: theme.colors.pink,
    back: theme.colors.white,
    text: theme.colors.black,
  },
  black: {
    front: theme.colors.eggplant,
    back: theme.colors.eggplant,
    text: theme.colors.white,
  },
  blackWhite: {
    front: theme.colors.eggplant,
    back: theme.colors.white,
    text: theme.colors.white,
  },
  blue: {
    front: theme.colors.blue,
    back: theme.colors.blue,
    text: theme.colors.black,
  },
  yellow: {
    front: theme.colors.yellow,
    back: theme.colors.yellow,
    text: theme.colors.black,
  },
  purple: {
    front: theme.colors.fadedPurple,
    back: theme.colors.dirtyPurple,
    text: theme.colors.eggplant,
  },
  whitePink: {
    front: theme.colors.white,
    back: theme.colors.pink,
    text: theme.colors.black,
  },
  whiteLime: {
    front: theme.colors.white,
    back: theme.colors.lime,
    text: theme.colors.black,
  },
  transparent: {
    front: 'transparent',
    back: 'transparent',
    text: 'currentColor'
  }
};

export const sizes = {
  small: {
    height: '34px',
    fontSize: theme.fontSizes.xxs,
    letterSpacing: '0.67px',
    offset: 6,
  },
  medium: {
    height: '42px',
    fontSize: theme.fontSizes.xs,
    letterSpacing: '0.67px',
    offset: 10,
  },
};

interface Props {
  size: string;
  color: string;
  inverted: boolean;
}

const StyledButton = styled.button(({ theme: { easing, fontFamilies, spacing }, color, inverted, size }: Props & ThemeType) => {
  const buttonColor = colors[color] || colors.default;
  const buttonSize = sizes[size] || sizes.medium;

  return `
    line-height: 1;
    white-space: nowrap;
    display: inline-block;
    cursor: pointer;
    position: relative;
    height: ${buttonSize.height};
    font-size: ${buttonSize.fontSize};
    text-transform: uppercase;
    font-family: ${fontFamilies.default}, sans-serif;
    font-weight: 700;
    line-height: 1.14;
    letter-spacing: ${buttonSize.letterSpacing};
    border: 0;
    padding: 0 !important;
    background-color: transparent;

    ${
      inverted
        ? `
      transform: translate(0, 0);
    `
        : `
      transform: translate(${buttonSize.offset / 2}px, ${buttonSize.offset / 2}px);
    `
    }

    > span {
      padding-left: ${spacing.x4};
      padding-right: ${spacing.x4};
      background-color: ${buttonColor.front};
      color: ${buttonColor.text};
      height: ${buttonSize.height};
      display: flex;
      align-items: center;
      justify-content: center;
      transition: transform 0.4s ${easing};
      transform: translate(-${buttonSize.offset / 2}px, -${buttonSize.offset / 2}px);

      ${
        inverted
          ? `
        transform: translate(0, 0);
      `
          : `
        transform: translate(-${buttonSize.offset / 2}px, -${buttonSize.offset / 2}px);
      `
      }

      span {
        transform: translateY(-1px);
      }
    }

    &:before {
      content: '';
      position: absolute;
      display: block;
      top: -${buttonSize.offset / 2}px;
      left: -${buttonSize.offset / 2}px;
      right: -${buttonSize.offset / 2}px;
      bottom: -${buttonSize.offset / 2}px;
      z-index: 1;
      background-color: transparent;
    }

    &:after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      border: 2px solid ${buttonColor.back};
      z-index: -1;
      transform: translate(5px, 5px);
      transition: transform 0.4s ${easing};
      box-sizing: border-box;

      ${
        inverted
          ? `
        transform: translate(0, 0);
      `
          : `
        transform: translate(${buttonSize.offset / 2}px, ${buttonSize.offset / 2}px);
      `
      }
    }

    &:hover {
      text-decoration: none;

      > span {
        transform: translate(0, 0);
      }
      
      &:after {
        ${
          inverted
            ? `
          transform: translate(${buttonSize.offset}px, ${buttonSize.offset}px);
        `
            : `
          transform: translate(0, 0);
        `
        }
      }
    }
`;
});

export default StyledButton;
