import React from 'react';
import Title from 'components/Title';
import { TicketType } from 'types';
import { StyledTicket, StyledCurrencyFlag } from './styles';
import Cta from 'components/Cta';

export const Ticket: React.FunctionComponent<TicketType> = ({
  title,
  ticketDetails: { soldOut, termsConditionsLink, conditions, description, price, ticketLink },
}) => (
  <StyledTicket className={soldOut ? 'sold-out' : ''}>
    <Title size="small">
      {soldOut && <span className="sold-out-banner">Sold Out</span>}
      <span dangerouslySetInnerHTML={{ __html: title }} />
      <span className="p4 ticket-price">{price}</span>
      <span className="p6 ticket-price">{conditions}</span>
    </Title>
    <div className="ticket-content">
      
      <div className="p4 ticket-description" dangerouslySetInnerHTML={{ __html: description ? description : '' }} />
      <div className="tickets-wrapper">
          {ticketLink && <Cta title={ticketLink?.title ? ticketLink.title : "Buy Tickets" } url={ticketLink.url ? ticketLink.url : ''} target={ticketLink?.target ? ticketLink.target : "_blank"} color="pink" inverted={true}></Cta>}
      </div>
      {termsConditionsLink ? (
        <a className="p4 ticket-terms_url" href={termsConditionsLink}>
          Terms and Conditions
        </a>
      ) : null}
    </div>
  </StyledTicket>
);

export default Ticket;
