import styled from 'styled-components';
import TitleWrapper from 'components/TitleWrapper/styles';
import { ThemeType } from 'types';
import WhiteTear from 'assets/images/hero-tear-green.png';


export default styled.section(
  ({ theme: {
    colors,
    mobileView,
    spacing,
    fontFamilies,
    utils: { rgba },
  }, }: ThemeType) => `
    background-color: ${colors.lime};
    color: ${colors.black};
    margin-top: -20px;
    margin-bottom: -${spacing.x8};
    padding-top: 68px;
    padding-bottom: 30px;
    position: relative;
    margin-left: -32px;
    margin-right: -32px;
    padding-left: 32px;
    padding-right: 32px;
    ${mobileView} {
      margin-bottom: 0;
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      background-color: ${rgba(colors.eggplant, 0.08)};
      background-image: url(${WhiteTear});
      background-repeat: repeat-x;
      background-position: top left;
      background-size: auto 100%;
      width: 100%;
      margin-left: -32px;
      margin-right: -32px;
      height: 20px;
      bottom: -10px;
      z-index: -1;
      box-shadow: 0 4px 7px ${rgba(colors.eggplant, 0.08)};
      
      ${mobileView} {
        height: 14px;
        bottom: -13px;
      }
    }
    ${TitleWrapper} {
      margin-bottom: 26px !important;

      ${mobileView} {
        margin-bottom: 44px !important;
      }
    }
`,
);
export const NewsWrapper = styled.div(
  ({ theme: { colors, mobileView } }: ThemeType) => `
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    margin: 0 -24px;
    ${NewsItem} {
      border-right: 1px solid ${colors.cream};
      &:last-of-type, &:nth-of-type(5n) {
        border-right: none;
      }
    }
    ${mobileView} {
      flex-direction: column;
      ${NewsItem} {
        border-right: none;
        border-bottom: 1px solid ${colors.cream};
        &:last-of-type {
          border-bottom: none;
        }
      }
    }
  `
)
export const NewsItem = styled.a(
  ({ theme: { colors, mobileView } }: ThemeType) => `
    display: flex;
    width: 20%;
    flex-direction: column;
    padding: 0 24px;
    margin-bottom: 40px;
    h4 {
      margin-bottom: 14px;
    }
    ${mobileView} {
      width: auto;
      padding: 20px 0;
      margin: 0 26px;
      h4 {
        font-size: 16px;
      }
    }
  `)
export const Reference = styled.div(
  ({ theme: { colors, fontSizes } }: ThemeType) => `
    text-transform: uppercase;
    font-weight: 900;
    line-height: 1.23;
    letter-spacing: 0.048em;
    font-size: ${fontSizes.xxs};
    margin-top: auto;
    vertical-align: middle;
    svg {
      margin-left: 0.5em;
      height: 0.8em;
      width: 0.8em;
    }
  `);
export const Note = styled.span(
  ({ theme: { colors, fontSizes } }: ThemeType) => `
    font-size: ${fontSizes.xxs};
    margin-bottom: 9px;
    
`,
);
