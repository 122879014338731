import styled from 'styled-components';
import { ThemeType } from 'types';
import Cta from 'components/Cta/styles';
import Title from 'components/Title/styles';
import SubTitle from 'components/SubTitle/styles';
import TitleWrapper from 'components/TitleWrapper/styles';
import WhiteTear from 'assets/images/hero-tear-cream.png';
import LeftAsset from 'assets/images/red-fill-woman-with-camera.png';
import RightAsset from 'assets/images/green-fill-woman-in-yellow-shirt.png';
import { StyledBlockInner } from 'components/Block/styles';

export default styled.section(
  ({ theme: { mobileView, spacing } }: ThemeType) => `
    margin-bottom: ${spacing.x6};

    ${Cta} {
      margin-top: ${spacing.x2};
    }

    ${Title} {

    }

    ${SubTitle} {

    }

    ${TitleWrapper} {
      margin-bottom: ${spacing.x7} !important;

      ${mobileView} {
        margin-bottom: 44px !important;
      }
    }

    ${StyledBlockInner} {


      ${mobileView} {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
    
`,
);

export const Tickets = styled.div(
  ({ theme: { mobileView } }: ThemeType) => `
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: flex-start;

    ${mobileView} {
      flex-wrap: wrap;
      justify-content: center;         
      padding-bottom: 0;
      margin-bottom: 42px;
    }
`,
);
export const MoreInfo = styled.a(
  ({ theme: { colors, easing } }: ThemeType) => `
    text-transform: uppercase;
    display: block;
    span {
      display: inline-block;
    }
  `
)
export const StyledTicket = styled.div(
  ({
    theme: {
      easing,
      mobileView,
      colors,
      fontFamilies,
      spacing,
      fontSizes,
      utils: { rgba },
    },
  }: ThemeType) => `
    border: none;
    position: relative;
    flex: 1 33%;
    margin-left: 16px;
    margin-right: 16px;
    display: flex;
    flex-direction: column;
    background-color: ${rgba(colors.eggplant, 0.08)};
    box-shadow: 8px 8px 7px ${rgba(colors.eggplant, 0.08)};
    transition: transform 0.4s ${easing}, box-shadow 0.4s ${easing};
    color: ${colors.black};
    ${mobileView} {
      width: 100%;
      flex: auto;
    }
    &:hover {
      transform: scale(1.008) translate(-4px, -4px);
      box-shadow: 14px 14px 10px ${rgba(colors.eggplant, 0.06)};

      &, * {
        text-decoration: none !important;
      }
    }

    &:after {
      content: '';
      height: 20px;
      background-image: url(${WhiteTear});
      background-size: 293px 20px;
    }

    ${Title} {
      width: 100%;
      min-height: 80px;
      color: ${colors.black};
      padding: 27px 40px 20px;
      display: flex;
      font-family: ${fontFamilies.default};
      span {
        transform: translateY(-2px);
      }
    }

    .ticket-content {
      background-color: ${colors.white};
      padding: 32px 40px 20px;
      position: relative;
      overflow-x: hidden;
      background-color: ${colors.cream};
      ${Cta} {
        margin-top: 27px;
      }

      ${mobileView} {
        text-align: center;
      }
    }

    &:nth-of-type(1) {
      border-top-left-radius: 88px;

      ${Title} {
        border-top-left-radius: 88px;
        background-color: ${colors.blue};
      }
      
      ${MoreInfo} {
        span {
          color: ${colors.blue};
        }
      }
    }

    &:nth-of-type(2) {
      ${Title} {
        background-color: ${colors.pink};
      }
      
      ${MoreInfo} {
        span {
          color: ${colors.pink};
        }
      }
    }

    &:nth-of-type(3) {
      border-top-right-radius: 88px;

      ${Title} {
        border-top-right-radius: 88px;
        background-color: ${colors.lime};
      }
      ${MoreInfo} {
        span {
          color: ${colors.lime};
        }
      }
    }

    ${mobileView} {                 
      min-width: auto;
      width: 100%;
      margin-bottom: 23px;
      border-top-right-radius: 0 !important;
      border-top-left-radius: 57px !important;
      min-width: none;
      max-width: none;
      width: 100%;

      ${Title} {
        border-top-right-radius: 0 !important;
        border-top-left-radius: 57px !important;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  `,
);
