import React, { SyntheticEvent } from 'react';
import StyledButton from './styles';
import { StyledButtonType } from 'types';

interface Button {
  onClick?: (e: SyntheticEvent) => void;
  children: React.ReactNode;
}

const Button: React.StatelessComponent<Button & StyledButtonType> = ({
  color = 'pink',
  inverted = false,
  size = 'medium',
  onClick,
  children,
}) => {
  return (
    <StyledButton color={color} inverted={inverted} size={size} onClick={onClick}>
      <span>
        <span>{children}</span>
      </span>
    </StyledButton>
  );
};

export default Button;
