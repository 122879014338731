import styled from 'styled-components';
import { ThemeType } from 'types';
import { ThemedComponent } from 'styles/components';

interface PositionProps extends ThemeType {
  right: boolean;
  scroll?: boolean;
}

interface WrapperProps extends PositionProps {
  isOpen: boolean;
}

interface Props extends PositionProps {
  color?: string;
  width?: string;
  maxWidth?: string;
  mobileWidth?: string;
  isOpen: boolean;
  scroll?: boolean;
  fillMobile?: boolean;
}

export const SlideMenuWrapper = styled.div(
  ({ isOpen, right }: WrapperProps) => `
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  opacity: ${isOpen ? '1' : '0'};
  transform: ${isOpen ? 'none' : `translate3d(${right ? '' : '-'}100%, 0px, 0px)`};
  pointer-events: ${isOpen ? 'all' : 'none'};
  transition: opacity 0.3s ease 0s;
  background-color: rgba(0, 0, 0, 0.3);
`,
);

export const SlideMenuBg = styled(ThemedComponent)(
  ({ theme: { colors, mobileView }, width, maxWidth, mobileWidth, isOpen, right }: Props) => `
  position: fixed;
  width: ${width ? width : '100%'};
  max-width: ${maxWidth || '100%'};
  height: 100%;
  left: ${right ? 'auto' : '0'};
  right: ${right ? '0' : 'auto'};
  opacity: ${isOpen ? '1' : '0'};
  transform: ${isOpen ? 'none' : `translate3d(${right ? '' : '-'}100%, 0px, 0px)`};
  transition: opacity 0.3s ease 0s, transform 0.2s ease 0.2s;
  background-color: ${colors.pink};

  ${mobileView} {
      width: ${mobileWidth || '100%'};
  }
`,
);

export const MenuBody = styled.div(
  ({ theme: { mobileView }, color, width, maxWidth, mobileWidth, isOpen, right }: Props) => `
    width: ${width ? width : '100%'};
    max-width: ${maxWidth || '100%'};
    height: 100%;
    ${right ? 'float: right;' : ''}
    outline: none;
    background: transparent;
    opacity: ${isOpen ? '1' : '0'};
    transform: ${isOpen ? 'translate3d(0, 0, 0)' : `translate3d(${right ? '' : '-'}100%, 0px, 0px)`};
    transition: opacity 0.3s ease 0s, transform 0.2s ease 0.2s;
    z-index: 1001;
    ${color ? `color: ${color};` : ''}

    ${mobileView} {
      width: ${mobileWidth || '100%'};
    }
  `,
);

export const MenuContent = styled.div(
  ({ right, scroll }: PositionProps) => `
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0 24px;
    ${scroll ? 'overflow: scroll;' : ''}

    .menu-header {
      display: flex;
      justify-content: ${right ? 'flex-end' : 'space-between'};
      padding: 24px 0;
    }
  `,
);
