import React from 'react';
import { ThemeProvider } from 'styled-components';
import Layout from 'components/Layout';
import Footer from 'components/Footer';
import Header from 'components/Header';
import MainMenu from 'components/MainMenu';
import SubscriptionMenu from 'components/SubscriptionMenu';
import ArtistMenu from 'components/ArtistMenu';
import { MODALS, MENUS, PAGE_WRAP_ID } from 'constants/layouts';
import FooterSection from 'sections/Footer';
import GlobalStyle from 'styles/GlobalStyle';
import theme from 'styles/theme';
import AbstractLayout from './AbstractLayout';
import Head from './Head';
import { ArtistProvider } from 'context/ArtistContext';
import SubscribeConsumer, { SubscribeProvider } from 'context/SubscribeContext';
class BlockLayout extends AbstractLayout {
  render() {
    const { showMenu, showModal } = this.state;

    const {
      seo,
      announcement,
      hideAnnouncement,
      headerMenu,
      mainMenu,
      footerMenu,
      secondaryFooterMenu,
      footerTopPartners,
      footerSecondaryPartners,
      headless,
    } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <SubscribeProvider>
          <SubscribeConsumer>
            {({ openedMenu, openSubscribeMenu, openMainMenu, closeMenu }) => (
              <>
                <Layout.Main>
                  <GlobalStyle />
                  <Head {...seo} />
                  <ArtistProvider>
                    {!headless && (
                      <>
                        <MainMenu
                          isOpen={String(openedMenu) === MENUS.MAIN}
                          close={closeMenu}
                          openSubscribeMenu={openSubscribeMenu}
                          items={mainMenu}
                        />
                        <SubscriptionMenu isOpen={String(openedMenu) === MENUS.SUBSCRIBE} close={closeMenu} />
                        <ArtistMenu showSubscribeModal={() => setState({ showModal: MODALS.MYSPLENDOUR })} />
                        <Header
                          openMainMenu={openMainMenu}
                          openMySplendourModal={this.openMySplendourModal}
                          openSubscribeMenu={openSubscribeMenu}
                          pageLinks={headerMenu}
                          announcement={announcement}
                          hideAnnouncement={hideAnnouncement}
                        />
                      </>
                    )}
                    <Layout.Page id={PAGE_WRAP_ID}>
                      {this.props.children}
                      {!headless && (
                        <FooterSection>
                          <Footer
                            mainMenuItems={footerMenu}
                            secondaryFooterItems={secondaryFooterMenu}
                            partnerTopItems={footerTopPartners && footerTopPartners.edges}
                            partnerItems={footerSecondaryPartners && footerSecondaryPartners.edges}
                            openSubscribeMenu={openSubscribeMenu}
                          />
                        </FooterSection>
                      )}
                    </Layout.Page>
                  </ArtistProvider>
                </Layout.Main>
              </>
            )}
          </SubscribeConsumer>
        </SubscribeProvider>
      </ThemeProvider>
    );
  }
}

export default BlockLayout;
