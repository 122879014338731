import styled from 'styled-components';
import Title from 'components/Title/styles';
import WhiteTear from 'assets/images/hero-tear-cream.png';
import Cta from 'components/Cta/styles';
import { ThemeType } from 'types';


export const StyledTicket = styled.a(
  ({
    theme: {
      easing,
      mobileView,
      colors,
      spacing,
      fontFamilies,
      utils: { rgba },
    },
  }: ThemeType) => `
    border: none;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: ${rgba(colors.blue, 0.08)};
    box-shadow: 8px 8px 7px ${rgba(colors.blue, 0.08)};
    transition: transform 0.4s ${easing}, box-shadow 0.4s ${easing};
    color: ${colors.black};
    &:hover {
      transform: scale(1.008) translate(-4px, -4px);
      box-shadow: 14px 14px 10px ${rgba(colors.blue, 0.06)};

      &, * {
        text-decoration: none !important;
      }
    }
    .sold-out-banner {
      display: block;
      width: 110%;
      text-align: center;
      text-transform: uppercase;
      margin: 0.5em 0;
      background-color: ${colors.pink};
      padding: 0.5em;
      position: absolute;
      top: 0;
      left: 50%;
      z-index: 5;
      font-size: 0.5em;
      transform-origin: center;
      transform: rotate(45deg) translate(-9%, 150%) !important;

    }

    &:after {
      content: '';
      height: 20px;
      background-image: url(${WhiteTear});
      background-size: 293px 20px;
    }

    ${Title} {
      width: 100%;
      min-height: 80px;
      color: ${colors.black};
      font-family: ${fontFamilies.default};
      padding: 27px 40px 20px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      position: relative;
      overflow: hidden;
      span {
        display: block;
        transform: translateY(-2px);
      }
    }

    .ticket-content {
      background-color: ${colors.cream};
      padding: 32px 40px 20px;
      position: relative;
      overflow-x: hidden;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: auto;
      .tickets-wrapper {
        margin-top: 27px;
        ${Cta} {
          margin-top: 0.5em;
          width: 100%;
        }
      }

      ${mobileView} {
        text-align: center;
      }
    }

    &:nth-of-type(3n + 1), &:nth-of-type(1) {
      ${Title} {
        background-color: ${colors.blue};
      }
    }

    &:nth-of-type(3n + 2), &:nth-of-type(2) {
      ${Title} {
        background-color: ${colors.pink};
      }
    }

    &:nth-of-type(3n + 3), &:nth-of-type(3) {

      ${Title} {
        background-color: ${colors.lime};
      }
    }

    ${mobileView} {                 
      min-width: auto;
      width: 100%;
      margin-bottom: 23px;
      min-width: none;
      max-width: none;
      width: 100%;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  `,
);
